import React from "react"
import PropTypes from "prop-types"

// Utilities
import kebabCase from "lodash/kebabCase"

// Components
import { Link, graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import ContentBox from "../components/content-box"
import PageHeader from "../components/headers/page-header"
import SectionHeader from "../components/headers/section-header"

const CategoriesPage = ({
                    data: {
                      allMarkdownRemark: { group },
                    },
                  }) => (
  <Layout>
    <SEO
      title={'Blog Categories'}
      description={`Comic, movie, documentary and book reviews related to politics, obscure pop-culture, Conspiracy Theories and the Occult.`}
    />
    <PageHeader><Link to={'/blog'}>Blog</Link></PageHeader>
    <ContentBox>
      <SectionHeader align={'left'}>Categories</SectionHeader>
      <ul>
        {group.map(category => (
          <li key={category.fieldValue}>
            <Link to={`/categories/${kebabCase(category.fieldValue)}/`}>
              {category.fieldValue} ({category.totalCount})
            </Link>
          </li>
        ))}
      </ul>
    </ContentBox>
  </Layout>
)

CategoriesPage.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      group: PropTypes.arrayOf(
        PropTypes.shape({
          fieldValue: PropTypes.string.isRequired,
          totalCount: PropTypes.number.isRequired,
        }).isRequired
      ),
    }),
  }),
}

export default CategoriesPage

export const pageQuery = graphql`
    query {
        allMarkdownRemark(limit: 2000) {
            group(field: frontmatter___categories) {
                fieldValue
                totalCount
            }
        }
    }
`